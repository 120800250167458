import { Controller } from 'stimulus';
import Dropzone from 'dropzone';
import { findElement, getMetaValue, insertAfter, removeElement } from '../utils/dom_helpers';
import { DirectUpload } from '@rails/activestorage';

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
  }

  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.emitDropzoneSuccess();
      }
    });
  }

  createHiddenInput() {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = this.source.inputTarget.name;
    insertAfter(input, this.source.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener('progress', (event) => this.uploadRequestDidProgress(event));
  }

  uploadRequestDidProgress(event) {
    // const element = this.source.element;
    const progress = (event.loaded / event.total) * 100;
    findElement(this.file.previewTemplate, '.dz-upload').style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit('processing', this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit('error', this.file, error);
    this.source.dropZone.emit('complete', this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit('success', this.file);
    this.source.dropZone.emit('complete', this.file);
  }
}

export default class extends Controller {
  static targets = ['input'];

  connect() {
    this.dropZone = new Dropzone(this.element, {
      clickable: this.element.querySelector('.attach-control'),
      autoQueue: false,
      addRemoveLinks: true,
      previewsContainer: this.element.querySelector('.dropzone-previews'),
      headers: () => ({ 'X-CSRF-Token': getMetaValue('csrf-token') }),
    });
    this.dropZone.on('addedfile', (file) => {
      setTimeout(() => {
        if (file.accepted) {
          new DirectUploadController(this, file).start();
        }
      });
    });
    this.dropZone.on('removedfile', (file) => {
      file.controller && removeElement(file.controller.hiddenInput);
    });
    this.dropZone.on('canceled', (file) => {
      file.controller && file.controller.xhr.abort();
    });
  }

  activate() {}

  get url() {
    return this.inputTarget.getAttribute('data-direct-upload-url');
  }

  reset() {
    this.dropZone.removeAllFiles();
  }
}
