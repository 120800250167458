import { Controller } from 'stimulus';
import { createPopper } from '@popperjs/core';
import maxSize from 'popper-max-size-modifier';

export default class DropdownController extends Controller {
  static targets = ['position'];

  connect() {
    const autoclose = this.element.hasAttribute('data-autoclose');

    this.target = document.getElementById(this.element.dataset.target);

    const applyMaxSize = {
      name: 'applyMaxSize',
      enabled: true,
      phase: 'beforeWrite',
      requires: ['maxSize'],
      fn({ state }) {
        const { height } = state.modifiersData.maxSize;
        state.styles.popper.maxHeight = `${height}px`;
      },
    };

    this.popper = createPopper(
      this.hasPositionTarget ? this.positionTarget : this.element,
      this.target,
      {
        strategy: 'fixed',
        placement: this.element.dataset.placement || 'bottom-end',
        modifiers: [maxSize, applyMaxSize],
      }
    );

    this.element.addEventListener('click', this.handleClick.bind(this));

    if (autoclose) {
      document.addEventListener('click', (e) => {
        if (this.isShown() && !this.target.contains(e.target) && !this.element.contains(e.target)) {
          this.hide();
        }
      });
    }
  }

  isShown() {
    return this.target.hasAttribute('data-show');
  }

  handleClick() {
    if (this.isShown()) {
      this.hide();
    } else {
      this.show();
    }
  }

  show() {
    this.target.setAttribute('data-show', '');
    this.popper.update();
  }

  hide() {
    this.target.removeAttribute('data-show');
  }

  disconnect() {
    if (this.popper) {
      this.popper.destroy();
    }
  }
}
