// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
import Sortable from 'stimulus-sortable';
import Carousel from 'stimulus-carousel';
import Flatpickr from 'stimulus-flatpickr';

const application = Application.start();

const context = require.context('controllers', true, /_controller\.js$/);
application.load(definitionsFromContext(context));

const componentsContext = require.context('../../components', true, /_controller\.js$/);
application.load(definitionsFromContext(componentsContext));

application.register('sortable', Sortable);
application.register('carousel', Carousel);
application.register('flatpickr', Flatpickr);

window.Stimulus = application;
