import { Controller } from 'stimulus';
import loadGoogleMaps, { mapStyles } from '../utils/maps_loader';
import debounce from '../utils/debounce';

export default class extends Controller {
  static targets = ['field'];
  static values = {
    skipImage: Boolean,
    baseUrl: String,
  };

  connect() {
    this.suggest = debounce(this.suggest.bind(this), 300);

    if (typeof google !== 'undefined') {
      this.initMap();
    } else {
      loadGoogleMaps(this.initMap.bind(this));
    }

    document.getElementById('form-submit').addEventListener('click', (e) => {
      e.preventDefault();
      const form = document.getElementById('filters-search-form');
      if (form) {
        window.Rails.fire(form, 'submit');
        const formData = new FormData(form);
        const data = [...formData.entries()];
        const asString = data
          .map((x) => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`)
          .join('&');

        window.history.pushState({}, '', `${this.baseUrlValue}?${asString}`);
        return;
      }

      const heroForm = document.getElementById('hero-search-form');
      if (heroForm) {
        const formData = new FormData(heroForm);
        const data = [...formData.entries()];
        const asString = data
          .map((x) => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`)
          .join('&');

        window.location = `${this.baseUrlValue}?${asString}`;
      }
    });
  }

  initMap() {
    if (this.map) {
      // already initialized
      return;
    }

    if (!document.getElementById('map')) {
      const hiddenMap = document.createElement('div');
      hiddenMap.id = 'map';
      hiddenMap.classList.add('hidden');
      document.body.append(hiddenMap);
    }

    this.map = new google.maps.Map(document.getElementById('map'), {
      center: new google.maps.LatLng(
        this.data.get('latitude') || 59.9138688,
        this.data.get('longitude') || 10.7522454
      ),
      zoom: this.data.get('latitude') == null ? 10 : 11,
      styles: mapStyles,
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
    });

    if (this.data.get('latitude') && this.data.get('longitude')) {
      this.updateDistanceMarker();
    }

    if (!this.hasFieldTarget) return;

    this.autocomplete = new google.maps.places.Autocomplete(this.fieldTarget);
    this.autocomplete.bindTo('bounds', this.map);
    this.autocomplete.setFields(['address_components', 'geometry', 'icon', 'name']);
    this.autocomplete.addListener('place_changed', this.placeChanged.bind(this));
  }

  changeInputValue(e) {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: e.params.value }, (results, status) => {
      if (status === 'OK') {
        if (results.length > 0) {
          this.updateMap(results[0]);
        }
      } else {
        console.log('Geocode was not successful for the following reason: ' + status);
      }
    });
  }

  suggest(e) {
    const service = new google.maps.places.AutocompleteService();
    if (!e.target.value) {
      return;
    }
    service.getPlacePredictions({ input: e.target.value }, (predictions) => {
      this.dispatch('suggest', { detail: { suggestions: predictions } });
    });
  }

  placeChanged() {
    let place = this.autocomplete.getPlace();
    this.updateMap(place);
  }

  updateMap(place) {
    if (!place.geometry) {
      window.alert(`No details available for input: ${place.name}`);
      return;
    }

    if (!document.getElementById('full_address_typed')) return;

    document.getElementById('full_address_typed').value =
      document.getElementById('header-address-input').value;

    this.updateDistanceMarker();
  }

  keydown(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  updateDistanceMarker() {
    if (this.cityCircle) {
      this.cityCircle.setMap(null);
    }
  }
}
