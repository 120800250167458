import Carousel from 'stimulus-carousel';

export default class extends Carousel {
  get defaultOptions() {
    return {
      loop: true,
      spaceBetween: 0,
      preloadImages: false,
      lazy: {
        loadPrevNext: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    };
  }
}
