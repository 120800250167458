import { Controller } from 'stimulus';

export default class CollapsibleTableController extends Controller {
  connect() {
    this.element.addEventListener('click', this.handleClick.bind(this));
    this.heads = this.element.querySelectorAll(`thead`);
    this.bodies = this.element.querySelectorAll(`tbody`);
  }

  handleClick(e) {
    if (e.target.classList.contains('collapse-control')) {
      const index = Array.prototype.slice
        .call(this.element.querySelectorAll('.collapse-control'))
        .findIndex((item) => item === e.target);

      if (e.target.dataset.state === 'closed') {
        this.heads[index].querySelector('tr:nth-child(2)').classList.remove('hidden');
        this.bodies[index].classList.remove('hidden');
      } else {
        this.heads[index].querySelector('tr:nth-child(2)').classList.add('hidden');
        this.bodies[index].classList.add('hidden');
      }

      e.target.dataset.state = e.target.dataset.state === 'closed' ? 'open' : 'closed';
    }
  }

  disconnect() {
    this.element.removeEventListener('click', this.handleClick);
  }
}
