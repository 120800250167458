import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {}

  clickOnDelete() {
    let parentNode = this.element.parentNode;
    parentNode.removeChild(this.element);
    if (this.data.get('spaceFacilityId')) {
      parentNode.removeChild(
        parentNode.querySelectorAll(`input[value="${this.data.get('spaceFacilityId')}"]`)[0]
      );
    }
  }
}
