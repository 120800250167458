import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['checkbox', 'input'];

  static values = {
    hide: Boolean,
    invert: Boolean,
  };

  connect() {
    this.toggle({ target: this.checkboxTarget });
  }

  toggle(event) {
    let isEnabled = event.target.checked;

    if (this.invertValue) {
      isEnabled = !isEnabled;
    }

    if (isEnabled) {
      this.inputTarget.removeAttribute('disabled');
      if (this.hideValue) {
        this.inputTarget.classList.remove('d-none', 'hidden');
      }
    } else {
      this.inputTarget.setAttribute('disabled', 'disabled');

      if (this.hideValue) {
        this.inputTarget.classList.add('d-none', 'hidden');
      }
    }
  }
}
