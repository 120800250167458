import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input', 'output', 'currency'];

  convert() {
    fetch(`/api/exchange_rates/${this.currencyTarget.value}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.outputTarget.value = (data.EUR * this.inputTarget.value).toFixed(2);
      });
  }
}
