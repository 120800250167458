import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input', 'target'];

  connect() {
    this.toggle({ target: this.inputTarget });
  }

  toggle(event) {
    const value = event.target.value;

    this.targets.findAllTargets('target').forEach((target) => {
      if (value === target.dataset.selectToggleValue) {
        target.classList.remove('hidden');
      } else {
        target.classList.add('hidden');
      }
    });
  }
}
