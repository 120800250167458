import { Controller } from 'stimulus';
import { createPopper } from '@popperjs/core';

export default class extends Controller {
  static values = {
    placement: { type: String, default: 'top' },
    offset: { type: Array, default: [0, 8] },
  };

  connect() {
    this.tooltip = document.createElement('div');
    this.tooltip.classList.add('tooltip');

    const arrow = document.createElement('div');
    arrow.classList.add('arrow');

    this.tooltip.innerText = this.element.getAttribute('title');
    this.element.removeAttribute('title');
    this.tooltip.append(arrow);

    this.element.after(this.tooltip);

    this.popperInstance = createPopper(this.element, this.tooltip, {
      placement: this.placementValue,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: this.offsetValue,
          },
        },
        {
          name: 'arrow',
          options: {
            element: arrow,
          },
        },
      ],
    });

    const showEvents = ['mouseenter', 'focus'];
    const hideEvents = ['mouseleave', 'blur'];

    showEvents.forEach((event) => {
      this.element.addEventListener(event, this.show.bind(this));
    });

    hideEvents.forEach((event) => {
      this.element.addEventListener(event, this.hide.bind(this));
    });
  }

  show() {
    this.tooltip.setAttribute('data-show', '');
    this.popperInstance.update();
  }

  hide() {
    this.tooltip.removeAttribute('data-show');
  }

  disconnect() {
    if (this.popperInstance) {
      this.popperInstance.destroy();
    }
  }
}
