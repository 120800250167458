import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.index = 0;
    if (document.location.hash) {
      this.switchTab(document.location.hash.replace('#', ''));
    }
  }

  getTabs() {
    return this.element.querySelectorAll('.tab-link');
  }

  getTabPanes() {
    return this.element.querySelectorAll('.tab-pane');
  }

  deactivateAll() {
    this.getTabs().forEach((tab) => tab.classList.remove('active'));
    this.getTabPanes().forEach((pane) => pane.classList.remove('active'));
  }

  setIndex(index) {
    this.index = index;
  }

  next() {
    this.deactivateAll();
    if (this.getTabs().length > 0) {
      this.getTabs()[this.index + 1].classList.add('active');
    }
    this.getTabPanes()[this.index + 1].classList.add('active');
    this.setIndex(this.index + 1);
  }

  prev() {
    this.deactivateAll();
    if (this.getTabs().length > 0) {
      this.getTabs()[this.index - 1].classList.add('active');
    }
    this.getTabPanes()[this.index - 1].classList.add('active');
    this.setIndex(this.index - 1);
  }

  activate(e) {
    const target = e.currentTarget;
    const targetId = target.getAttribute('href').replace('#', '');
    this.switchTab(targetId);
    e.preventDefault();
  }

  switchTab(targetId) {
    if (this.element.querySelector(`#${targetId}-tab`)) {
      this.deactivateAll();
      this.element.querySelector(`#${targetId}-tab`).classList.add('active');
      this.element.querySelector(`#${targetId}-content`).classList.add('active');
      const activeTab = this.element.querySelector('.tab-link.active');
      this.setIndex(Array.from(this.getTabs()).indexOf(activeTab));
      document.location.hash = targetId;
    }
  }
}
