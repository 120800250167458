import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    refresh: Boolean,
    classToWatch: String,
    fieldChanged: String,
  };

  connect() {
    if (this.classToWatchValue) {
      const classesToWatch = document.querySelectorAll(`.${this.classToWatchValue}`);
      classesToWatch.forEach((element) => {
        element.addEventListener('change', () => {
          let str = element.name;
          let start = str.indexOf('[') + 1; // Add 1 to exclude the bracket itself
          let end = str.indexOf(']');
          let result = str.substring(start, end);
          let fieldChangedInput = this.element.querySelector('#field_changed');
          if (fieldChangedInput) {
            fieldChangedInput.value = result;
          }
          this.submit();
        });
      });
    } else {
      this.element.addEventListener('change', this.submit.bind(this));
    }
  }

  disconnect() {
    this.element.removeEventListener('change', this.submit);
  }

  submit() {
    let refreshInput = this.element.querySelector('#refresh');

    if (refreshInput) {
      refreshInput.value = this.refreshValue ? 1 : 0;
    }

    if (this.refreshValue) {
      this.element.requestSubmit();
    } else {
      window.Turbo.navigator.submitForm(this.element);
    }
  }
}
