import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['item'];

  connect() {
    this.availableTargets = this.itemTargets;
  }

  addNew() {
    let newTabTrigger = this.availableTargets.shift();
    newTabTrigger.classList.remove('hidden');
    newTabTrigger.querySelector('a').click();
  }
}
