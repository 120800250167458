import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.render();
  }

  render() {
    this.element.querySelectorAll('.array-input-control').forEach((el) => el.remove());
    const items = this.element.querySelectorAll('.array-input-item');
    items.forEach((item, index) => {
      if (index === items.length - 1) {
        const add = document.createElement('button');
        add.classList.add('array-input-control', 'array-input-remove');
        add.innerText = '➕';
        add.addEventListener('click', () => {
          const newItem = item.cloneNode(true);
          newItem.querySelectorAll('input, select, textarea').forEach((input) => {
            input.value = '';
          });
          this.element.append(newItem);
          this.render();
        });
        item.append(add);
      } else {
        const remove = document.createElement('button');
        remove.classList.add('array-input-control', 'array-input-remove');
        remove.innerText = '➖';
        remove.addEventListener('click', () => {
          item.remove();
          this.render();
        });
        item.append(remove);
      }
    });
  }
}
