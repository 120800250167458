import Sortable from 'stimulus-sortable';
import Rails from '@rails/ujs';

export default class extends Sortable {
  end() {
    Rails.ajax({
      url: this.element.dataset.sortUrl,
      type: 'PATCH',
      data: JSON.stringify(
        Array.from(this.element.querySelectorAll('.drag-item')).map((el) => el.dataset.id)
      ),
    });
  }

  get defaultOptions() {
    return {
      animation: 100,
    };
  }
}
