import { Controller } from 'stimulus';
import { createPopper } from '@popperjs/core';

export default class DropdownController extends Controller {
  static targets = ['step'];
  static values = {
    next: String,
    done: String,
  };

  connect() {
    this.index = 1;
    window.startGuide = (index) => {
      this.showStep(index);
    };
  }

  show() {
    this.showStep(this.index);
  }

  showStep(index) {
    const step = this.stepTargets.find(
      (step) => parseInt(step.dataset.index, 10) === parseInt(index, 10)
    );

    if (!step) {
      throw new Error(`Step ${index} not found`);
    }

    const target = document.querySelector(step.dataset.selector);

    this.index = index;

    target.scrollIntoView({ behavior: 'smooth', block: 'center' });

    this.veil = document.createElement('div');
    this.veil.classList.add('fixed', 'inset-0', 'z-50');
    document.body.append(this.veil);

    this.popout = step.dataset.popout
      ? step.dataset.popout === 'true'
        ? target
        : this.element.querySelector(step.dataset.popout)
      : false;

    if (this.popout) {
      this.popout.classList.add('relative', 'z-[1234]');
      const rect = this.popout.getBoundingClientRect();

      const left = rect.left + window.scrollX;
      const top = rect.top + window.scrollY;

      this.popoutMask = document.createElement('div');
      this.popoutMask.classList.add('absolute', 'inset-0', 'z-50', 'rounded-lg');
      // this.popoutMask.style = 'box-shadow: rgba(1, 1, 1, 0.2) 0 0 0 2000px;';
      this.popoutMask.style.top = `${top - 10}px`;
      this.popoutMask.style.left = `${left - 10}px`;
      this.popoutMask.style.width = `${rect.width + 20}px`;
      this.popoutMask.style.height = `${rect.height + 20}px`;
      document.body.append(this.popoutMask);
    }

    this.popperContainer = document.createElement('div');
    this.popperContainer.classList.add(
      'hidden',
      'bg-white',
      'shadow-[0_0_10px_10px_rgba(0,0,0,0.05)]',
      'drop-shadow-lg',
      'z-50',
      'rounded-lg'
    );
    this.popperContainer.innerHTML = `
      <div data-popper-arrow class="arrow big"></div>
      <div class="p-4 max-w-sm">
        <div class="flex flex-row gap-2 items-start justify-between">
          <div class="text-lg font-semibold">${step.dataset.title}</div>
          <i class="icon close scale-125 p-2 cursor-pointer relative top-1" data-role="close"></i>
        </div>
        <div class="my-4">${step.dataset.content}</div>
        <div class="flex flex-row justify-between gap-2">
          <div class="text-muted text-sm">${index} of ${this.stepTargets.length}</div>
          <div>
            <button
              type="button"
              class="btn btn-primary btn-sm"
              data-role="submit"
            >
              ${index === this.stepTargets.length ? this.doneValue : this.nextValue}
            </button>
          </div>
        </div>
      </div>
    `;
    this.popperContainer
      .querySelector('[data-role=close]')
      .addEventListener('click', this.close.bind(this));
    this.popperContainer
      .querySelector('[data-role=submit]')
      .addEventListener('click', this.next.bind(this));

    document.body.append(this.popperContainer);

    this.popper = createPopper(target, this.popperContainer, {
      placement: step.dataset.placement || 'right',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 20],
          },
        },
        {
          name: 'arrow',
          options: {
            padding: 15,
          },
        },
      ],
    });
    this.popperContainer.setAttribute('data-show', '');
    this.popperContainer.classList.remove('hidden');
    this.popper.update();
  }

  next(e) {
    e.preventDefault();
    this.close();

    if (this.index < this.stepTargets.length) {
      this.showStep(this.index + 1);
    }
  }

  close() {
    this.popper.destroy();
    this.veil.remove();
    this.popperContainer.remove();
    if (this.popoutMask) {
      this.popoutMask.remove();
    }
    if (this.popout) {
      this.popout.classList.remove('relative', 'z-[1234]');
    }

    this.popper = null;
    this.veil = null;
    this.popperContainer = null;
    this.popoutMask = null;
    this.popout = null;
  }
}
