import { Controller } from 'stimulus';
import loadGoogleMaps, {
  mapStyles,
  multiPolygonCoordinatesToLatLngArray,
  polygonCoordinatesToLatLngArray,
} from '../utils/maps_loader';
import debounce from '../utils/debounce';

const getDataFromGeoCoderResult = (place) => {
  const geoCoderData = place.address_components;

  return geoCoderData.reduce((acc, { types, short_name: value }) => {
    const type = types[0];

    switch (type) {
      case 'route':
        return { ...acc, street: value };
      case 'locality':
        return { ...acc, city: value };
      case 'country':
        return { ...acc, country: value };
      case 'political':
        return { ...acc, city: value };
      case 'street_number':
        return { ...acc, houseNumber: value };
      case 'sublocality_level_1':
        return { ...acc, cityDistrict: value };
      case 'postal_town':
        return { ...acc, city: value };
      default:
        return acc;
    }
  }, {});
};

const getLocationPolygons = async (place) => {
  return new Promise((resolve, reject) => {
    if (['production', 'staging', 'development'].includes(window.appConfig.env)) {
      const long_name = place.address_components.map((component) => component.long_name).join(', ');
      const encoded_place = encodeURIComponent(long_name);
      const url = `https://nominatim.openstreetmap.org/search?q=${encoded_place}&format=json&polygon_geojson=1`;
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          if (data.length === 0) {
            resolve(place);
          } else {
            resolve(data[0]);
          }
        })
        .catch((error) => {
          console.error('Failed to fetch data from Nominatim API:', error);
          reject(error);
        });
    } else {
      resolve(place);
    }
  });
};

export default class extends Controller {
  static targets = [
    'field',
    'map',
    'latitude',
    'longitude',
    'country',
    'city',
    'city_district',
    'street',
    'house_number',
    'raw_data',
    'distance',
    'image_base64',
    'zoom',
    'selection_path',
    'polygon_type',
  ];

  static values = {
    skipImage: Boolean,
  };

  connect() {
    this.suggest = debounce(this.suggest.bind(this), 300);

    this.is_search = this.data.get('isSearch');

    if (typeof google !== 'undefined') {
      this.initMap();
    } else {
      loadGoogleMaps(this.initMap.bind(this));
    }
  }

  initMap() {
    if (this.map) {
      // already initialized
      return;
    }

    let _this = this;

    let mapTarget;

    if (!this.hasMapTarget) {
      const hiddenMap = document.createElement('div');
      hiddenMap.id = 'map';
      hiddenMap.classList.add('hidden');
      document.body.append(hiddenMap);
      mapTarget = hiddenMap;
    } else {
      mapTarget = this.mapTarget;
    }

    this.map = new google.maps.Map(mapTarget, {
      center: new google.maps.LatLng(
        this.data.get('latitude') || 59.9138688,
        this.data.get('longitude') || 10.7522454
      ),
      zoom: this.data.get('latitude') == null ? 10 : 15,
      styles: mapStyles,
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
    });

    let icon = {
      url: `${this.is_search === 'true' ? '/map-marker-primary.svg' : '/new-map-marker.svg'}`,
      size: new google.maps.Size(50, 50),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(50 / 2, 50 / 2),
    };

    this.marker = new google.maps.Marker({
      map: this.map,
      icon: icon,
      anchorPoint: new google.maps.Point(-25, -25),
    });

    if (this.hasPolygon_typeTarget && this.data.get('latitude') && this.data.get('longitude')) {
      this.marker.setPosition(
        new google.maps.LatLng(this.data.get('latitude'), this.data.get('longitude'))
      );

      if (this.polygon_typeTarget.value && this.polygon_typeTarget.value !== 'Circle') {
        this.coordinates = JSON.parse(this.selection_pathTarget.value);
        this.marker.setVisible(false);
      } else {
        this.coordinates = { lat: this.data.get('latitude'), lng: this.data.get('longitude') };
        this.marker.setVisible(true);
      }
      this.updateDistanceMarker();
    }

    if (document.getElementById('distance-value')) {
      document.getElementById('distance-value').innerText = `${parseFloat(
        this.distanceTarget.value
      )} km`;
    }

    if (!this.hasFieldTarget) return;

    this.autocomplete = new google.maps.places.Autocomplete(this.fieldTarget);
    this.autocomplete.bindTo('bounds', this.map);
    this.autocomplete.setFields(['address_components', 'geometry', 'icon', 'name']);
    this.autocomplete.addListener('place_changed', this.placeChanged.bind(this));
  }

  changeInputValue(e) {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: e.params.value }, (results, status) => {
      if (status === 'OK') {
        if (results.length > 0) {
          const result =
            results.find((place) => place.place_id === e.target.dataset.placesIdParam) ||
            results[0];

          this.updateMap(result);
        }
      } else {
        console.log('Geocode was not successful for the following reason: ' + status);
      }
    });
  }

  suggest(e) {
    const service = new google.maps.places.AutocompleteService();
    if (!e.target.value) {
      return;
    }
    service.getPlacePredictions({ input: e.target.value }, (predictions) => {
      this.dispatch('suggest', { detail: { suggestions: predictions } });
    });
  }

  placeChanged() {
    let place = this.autocomplete.getPlace();

    getLocationPolygons(place).then((nomPlace) => {
      if (nomPlace.geojson) {
        this.coordinates = nomPlace.geojson.coordinates;
      } else {
        this.coordinates = place.geometry.location;
      }

      this.updateMap(place, nomPlace);
    });
  }

  updateMap(place, nominatim_place) {
    this.marker.setVisible(false);

    if (!place.geometry) {
      window.alert(`No details available for input: ${place.name}`);
      return;
    }

    let center;

    if (nominatim_place && nominatim_place.osm_type) {
      center = {
        lat: parseFloat(nominatim_place.lat),
        lng: parseFloat(nominatim_place.lon),
      };
    } else {
      center = place.geometry.location;
    }

    if (nominatim_place && nominatim_place.osm_type !== 'relation') {
      if (place.geometry.viewport) {
        this.map.fitBounds(place.geometry.viewport);
        this.latitudeTarget.value = place.geometry.location.lat();
        this.longitudeTarget.value = place.geometry.location.lng();
      } else {
        this.map.setCenter(center, 17);
        this.latitudeTarget.value = center.lat;
        this.longitudeTarget.value = center.lng;
      }
      this.marker.setPosition(center);
      this.marker.setVisible(true);
    } else if (nominatim_place && nominatim_place.osm_type === 'relation') {
      this.map.setCenter(center, 17);
      this.latitudeTarget.value = center.lat;
      this.longitudeTarget.value = center.lng;
    } else {
      this.map.fitBounds(place.geometry.viewport);
      this.latitudeTarget.value = place.geometry.location.lat();
      this.longitudeTarget.value = place.geometry.location.lng();
      this.marker.setPosition(center);
      this.marker.setVisible(true);
    }

    let structuredPlace;

    if (nominatim_place) {
      structuredPlace = getDataFromGeoCoderResult(this.autocomplete.getPlace());
    } else {
      structuredPlace = getDataFromGeoCoderResult(place);
    }

    this.countryTarget.value = structuredPlace.country;
    this.cityTarget.value = structuredPlace.city;
    this.city_districtTarget.value = structuredPlace.cityDistrict;
    this.streetTarget.value = structuredPlace.street;
    this.house_numberTarget.value = structuredPlace.houseNumber;
    this.raw_dataTarget.value = JSON.stringify(place.address_components);

    if (nominatim_place) {
      if (nominatim_place.osm_type === 'relation') {
        this.polygon_typeTarget.value = nominatim_place.geojson.type;
      } else {
        this.polygon_typeTarget.value = 'Circle';
      }
    }

    this.updateDistanceMarker();
  }

  keydown(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  distance_changed(event) {
    this.updateDistanceMarker();

    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  GMapCircle(lat, lng, rad, detail = 8) {
    var coords = [];

    var r = 6371;

    var pi = Math.PI;

    var _lat = (lat * pi) / 180;
    var _lng = (lng * pi) / 180;
    var d = rad / 1000 / r;

    var i = 0;

    for (i = 0; i <= 360; i += detail) {
      var brng = (i * pi) / 180;

      var pLat = Math.asin(
        Math.sin(_lat) * Math.cos(d) + Math.cos(_lat) * Math.sin(d) * Math.cos(brng)
      );
      var pLng =
        ((_lng +
          Math.atan2(
            Math.sin(brng) * Math.sin(d) * Math.cos(_lat),
            Math.cos(d) - Math.sin(_lat) * Math.sin(pLat)
          )) *
          180) /
        pi;
      pLat = (pLat * 180) / pi;

      coords.push([pLat, pLng]);
    }

    return coords;
  }

  updateDistanceMarker() {
    if (
      this.hasDistanceTarget &&
      this.hasPolygon_typeTarget &&
      parseFloat(this.distanceTarget.value) > 0 &&
      this.marker
    ) {
      if (this.cityCircle) {
        this.cityCircle.setMap(null);
      }

      if (document.getElementById('distance-value')) {
        document.getElementById('distance-value').innerText = `${parseFloat(
          this.distanceTarget.value
        )} km`;
      }

      const map = this.map;

      if (
        this.polygon_typeTarget.value === 'Polygon' ||
        this.polygon_typeTarget.value === 'MultiPolygon'
      ) {
        let latLngArrays;

        if (this.polygon_typeTarget.value === 'Polygon') {
          latLngArrays = polygonCoordinatesToLatLngArray(this.coordinates);
        } else {
          latLngArrays = multiPolygonCoordinatesToLatLngArray(this.coordinates);
        }

        this.cityCircle = new google.maps.Polygon({
          paths: latLngArrays,
          strokeColor: '#175cff',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#175cff',
          fillOpacity: 0.35,
          map,
        });
        var bounds = new google.maps.LatLngBounds();
        this.cityCircle.getPath().forEach(function (element) {
          bounds.extend(element);
        });
        map.fitBounds(bounds);

        this.distanceTarget.setAttribute('disabled', 'disabled');
        this.selection_pathTarget.value = JSON.stringify(this.coordinates);
      } else {
        this.cityCircle = new google.maps.Circle({
          strokeColor: '#175cff',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#175cff',
          fillOpacity: 0.35,
          map,
          center: this.marker.position,
          radius: parseFloat(this.distanceTarget.value) * 1000,
        });
        map.fitBounds(this.cityCircle.getBounds());
        this.distanceTarget.removeAttribute('disabled', 'disabled');

        this.selection_pathTarget.value = JSON.stringify(
          this.GMapCircle(
            this.latitudeTarget.value,
            this.longitudeTarget.value,
            parseFloat(this.distanceTarget.value) * 1000
          )
        );
      }

      this.zoomTarget.value = map.zoom;
    }
  }
}
