import { Controller } from 'stimulus';

export default class extends Controller {
  change(e) {
    if (e.target.value === this.element.dataset.condition) {
      document.querySelectorAll(this.element.dataset.target).forEach((el) => {
        el.querySelectorAll('input, select, textarea').forEach(
          (input) => (input.name = input.name.replace('__hidden__', ''))
        );
        el.classList.remove('hidden');
      });
    } else {
      document.querySelectorAll(this.element.dataset.target).forEach((el) => {
        el.querySelectorAll('input, select, textarea').forEach(
          (input) => (input.name = `__hidden__${input.name}`)
        );
        el.classList.add('hidden');
      });
    }
  }
}
