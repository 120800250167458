import { Controller } from 'stimulus';
import loadGoogleMaps, { mapStyles } from '../utils/maps_loader';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['map', 'list', 'results'];

  static values = {
    objects: Array,
    iconPrimary: String,
    iconSecondary: String,
    centerOnMarker: Boolean,
    showDetails: Boolean,
    isMobile: Boolean,
  };

  connect() {
    this.highlighted = null;
    this.list = [];
    this.resize();

    // window.addEventListener('resize', () => this.resize());

    loadGoogleMaps(this.initMap.bind(this));
  }

  resize() {
    // this.element.style.height = window.innerHeight - 190 + 'px';
    // this.listTarget.style.maxHeight = window.innerHeight - 190 + 'px';
  }

  initMap() {
    if (this.map) {
      // already initialized
      return;
    }

    let iconSize = 50;

    this.iconPrimary = {
      url: this.iconPrimaryValue || '/map-marker-primary.svg',
      size: new google.maps.Size(iconSize, iconSize),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(iconSize / 2, iconSize / 2),
    };

    this.iconSecondary = {
      url: this.iconSecondaryValue || '/map-marker-secondary.svg',
      size: new google.maps.Size(iconSize, iconSize),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(iconSize / 2, iconSize / 2),
    };

    this.iconDanger = {
      url: '/map-marker-danger.svg',
      size: new google.maps.Size(iconSize, iconSize),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(iconSize / 2, iconSize / 2),
    };

    if (this.hasObjectsValue) {
      this.initObjects(this.objectsValue);
    } else {
      this.initObjects([]);
    }
  }

  initObjects(objectsValue) {
    if (!this.map) {
      const mapTarget = this.hasMapTarget ? this.mapTarget : document.getElementById('map');
      this.map = new google.maps.Map(mapTarget, {
        // center:
        //   objectsValue.length > 0
        //     ? new google.maps.LatLng(objectsValue[0].latitude, objectsValue[0].longitude)
        //     : new google.maps.LatLng(59.9138688, 10.7522454),
        zoom: objectsValue.length > 0 ? 15 : this.isMobileValue ? 15 : 7,
        styles: mapStyles,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: true,
        fullscreenControl: false,
      });

      google.maps.event.addListener(this.map, 'click', () => {
        if (this.popup) {
          this.popup.setMap(null);
          this.popup = null;
        }
      });
    }

    this.emptyMarkers();

    if (objectsValue.length > 0) {
      const bounds = new google.maps.LatLngBounds();

      objectsValue.forEach((object) => {
        let marker = new google.maps.Marker({
          map: this.map,
          icon: this.iconSecondary,
          clickable: true,
          anchorPoint: new google.maps.Point(-25, -25),
        });

        marker.setPosition(new google.maps.LatLng(object.latitude, object.longitude));
        marker.setVisible(true);

        marker.addListener('click', () => {
          this.highlight(object.id, true, this.centerOnMarkerValue, object.search_id);
        });

        bounds.extend(marker.getPosition());

        this.list.push({ id: object.id, marker: marker });
      });

      google.maps.event.addListenerOnce(this.map, 'bounds_changed', () => {
        this.map.setZoom(this.map.getZoom() - 0.1);

        if (this.map.getZoom() > 15) {
          this.map.setZoom(15);
        }
      });
      this.map.fitBounds(bounds);
    }
  }

  emptyMarkers() {
    this.list.forEach((object) => {
      object.marker.setMap(null);
    });
    this.list = [];
  }

  resultsTargetConnected(el) {
    this.initObjects(JSON.parse(el.dataset.objects));
  }

  closePopup() {
    if (this.popup) {
      this.popup.setMap(null);
    }
  }

  highlight(newId, scrollInList, fitBounds = false, searchId = null) {
    if (this.highlighted) {
      this.highlighted.marker.setIcon(this.iconSecondary);
      this.element
        .querySelector(`#space-tenant-${this.highlighted.id}`)
        .classList.remove('border-primary');
    }

    let object = this.list.find((object) => object.id === newId);
    if (object) {
      if (this.prevMarker) {
        this.prevMarker.setZIndex(1);
      }

      this.highlighted = object;
      object.marker.setIcon(this.iconPrimary);
      let listElement = this.element.querySelector(`#space-tenant-${this.highlighted.id}`);
      listElement.classList.add('border-primary');
      object.marker.setZIndex(20000);

      if (scrollInList) {
        listElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }

      if (this.showDetailsValue) {
        if (this.isMobileValue) {
          window.Stimulus.getControllerForElementAndIdentifier(document.body, 'landing').showMap();
        }
        this.showDetails(newId, searchId);
      } else if (fitBounds) {
        this.map.panTo(object.marker.getPosition());
        if (this.map.zoom < 10) {
          this.map.setZoom(10);
        }
      }

      this.prevMarker = object.marker;
    }
  }

  showDetails(id, search_id) {
    const object = this.list.find((object) => object.id === parseInt(id, 10));

    if (object) {
      class SpacePopup extends google.maps.OverlayView {
        constructor(position, content, isMobileValue) {
          super();
          this.position = position;
          this.isMobileValue = isMobileValue;
          this.containerDiv = document.createElement('div');
          this.containerDiv.innerHTML = content;
          if (this.isMobileValue) {
            this.containerDiv.classList.add('fixed');
            this.containerDiv.style.zIndex = '200000';
            this.containerDiv.style.left = '10px';
            this.containerDiv.style.right = '10px';
            this.containerDiv.style.bottom = '10px';
            this.containerDiv.style.padding = '10px';
          } else {
            this.containerDiv.classList.add('absolute');
            this.containerDiv.style.zIndex = '200000';
            this.containerDiv.style.marginTop = '17px';
            this.containerDiv.style.marginLeft = '-10px';
          }
          SpacePopup.preventMapHitsAndGesturesFrom(this.containerDiv);
        }
        onAdd() {
          if (this.isMobileValue) {
            document.body.appendChild(this.containerDiv);
          } else {
            this.getPanes().floatPane.appendChild(this.containerDiv);
          }
        }
        onRemove() {
          if (this.containerDiv.parentNode) {
            this.containerDiv.parentNode.removeChild(this.containerDiv);
          }
        }
        draw() {
          if (!this.isMobileValue) {
            const divPosition = this.getProjection().fromLatLngToDivPixel(this.position);
            const display =
              Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ? 'block' : 'none';

            if (display === 'block') {
              this.containerDiv.style.left = divPosition.x + 'px';
              this.containerDiv.style.top = divPosition.y + 'px';
            }

            if (this.containerDiv.style.display !== display) {
              this.containerDiv.style.display = display;
            }
          }
        }

        center() {
          if (this.isMobileValue) return false;

          const projection = this.getProjection();
          if (projection) {
            const overlayPixel = projection.fromLatLngToDivPixel(this.position);

            const overlayWidth = this.containerDiv.offsetWidth;
            const overlayHeight = this.containerDiv.offsetHeight;

            const centerPixelX = overlayPixel.x + overlayWidth / 2;
            const centerPixelY = overlayPixel.y + overlayHeight / 2;

            const centerLatLng = projection.fromDivPixelToLatLng(
              new google.maps.Point(centerPixelX, centerPixelY)
            );

            this.map.panTo(centerLatLng);
          }
        }
      }

      this.closePopup();

      this.popup = new SpacePopup(
        object.marker.getPosition(),
        `<div style="width:300px;height:350px;overflow:hidden;">
          <div class="bg-white p-4" style="width: 100px;">
            <div class="spinner"></div>
          </div>
        </div>
        `,
        this.isMobileValue
      );
      this.popup.setMap(this.map);

      Rails.ajax({
        url: `/search/${search_id}/space/${id}`,
        type: 'POST',
        complete: (res) => {
          this.popup.containerDiv.innerHTML = res.response;
          this.popup.center();
        },
      });
    }
  }

  hover(element) {
    this.highlight(parseInt(element.currentTarget.dataset.target), false, false);
  }

  click(element) {
    this.highlight(
      parseInt(element.currentTarget.dataset.target),
      false,
      true,
      parseInt(element.currentTarget.dataset.searchId)
    );
  }
}
