import { Controller } from 'stimulus';
import loadGoogleMaps, {
  mapStyles,
  multiPolygonCoordinatesToLatLngArray,
  polygonCoordinatesToLatLngArray,
} from '../utils/maps_loader';

export default class extends Controller {
  static values = {
    lat: Number,
    lng: Number,
    static: Boolean,
    radius: Number,
    polygonType: String,
    selectionPath: String,
  };

  connect() {
    loadGoogleMaps(this.initMap.bind(this));
  }

  initMap() {
    if (this.map) {
      // already initialized
      return;
    }

    let mapOptions = {
      zoom: 15,
      styles: mapStyles,
      zoomControl: true,
      mapTypeControl: true,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: true,
      fullscreenControl: false,
    };

    if (this.staticValue) {
      mapOptions = {
        ...mapOptions,
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        draggable: false,
        scrollwheel: false,
        disableDoubleClickZoom: true,
        keyboardShortcuts: false,
      };
    }

    this.map = new google.maps.Map(this.element, {
      ...mapOptions,
      center: new google.maps.LatLng(this.latValue, this.lngValue),
    });

    let icon = {
      url: '/map-marker-primary.svg',
      size: new google.maps.Size(50, 50),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(50 / 2, 50 / 2),
    };

    this.marker = new google.maps.Marker({
      map: this.map,
      icon: icon,
      anchorPoint: new google.maps.Point(-25, -25),
    });

    this.marker.setPosition(new google.maps.LatLng(this.latValue, this.lngValue));
    this.marker.setVisible(true);

    if (this.polygonTypeValue === 'Polygon' || this.polygonTypeValue === 'MultiPolygon') {
      let latLngArrays;

      if (this.polygonTypeValue === 'Polygon') {
        latLngArrays = polygonCoordinatesToLatLngArray(JSON.parse(this.selectionPathValue));
      } else {
        latLngArrays = multiPolygonCoordinatesToLatLngArray(JSON.parse(this.selectionPathValue));
      }
      this.cityCircle = new google.maps.Polygon({
        paths: latLngArrays,
        strokeColor: '#175cff',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#175cff',
        fillOpacity: 0.35,
        map: this.map,
      });
      const bounds = new google.maps.LatLngBounds();
      this.cityCircle.getPath().forEach(function (element) {
        bounds.extend(element);
      });

      let map = this.map;
      google.maps.event.addListenerOnce(this.map, 'bounds_changed', function () {
        map.setZoom(map.zoom * 1.15);
      });
      this.map.fitBounds(bounds);
      this.marker.setVisible(false);
    } else {
      if (parseFloat(this.radiusValue) > 0) {
        this.cityCircle = new google.maps.Circle({
          strokeColor: '#175cff',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#175cff',
          fillOpacity: 0.35,
          map: this.map,
          center: this.marker.position,
          radius: parseFloat(this.radiusValue) * 1000,
        });

        let map = this.map;

        google.maps.event.addListenerOnce(this.map, 'bounds_changed', function () {
          map.setZoom(map.zoom * 1.15);
        });

        this.map.fitBounds(this.cityCircle.getBounds());
        this.marker.setVisible(false);
      }
    }
  }
}
