import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['item'];
  connect() {
    this.init();
  }

  get activeClass() {
    return ['active'];
  }

  init() {
    const allEntries = [];

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = allEntries.findIndex((existing) => existing.target === entry.target);
          if (index === -1) {
            allEntries.push(entry);
          } else {
            allEntries[index] = entry;
          }
        });

        const sorted = allEntries.sort((a, b) => b.intersectionRatio - a.intersectionRatio);

        let done = false;
        this.itemTargets.forEach((target, index) => {
          const el = document.getElementById(target.dataset.value);
          const found = sorted.find((entry) => entry.target === el);
          if (found) {
            if (index === sorted.length - 1 && found.intersectionRatio === 1) {
              // if it's the last item and is fully visible - force set it to active
              this.itemTargets.forEach((target) => target.classList.remove(...this.activeClass));
              target.classList.add(...this.activeClass);
              done = true;
            } else if (!done && found.intersectionRatio === 1) {
              // otherwise pick first intersecting by dom order and mark it as active
              target.classList.add(...this.activeClass);
              done = true;
            } else if (done && found.intersectionRatio === 1) {
              // it's done, the rest can be marked as inactive
              target.classList.remove(...this.activeClass);
            } else if (sorted.indexOf(found) === 0) {
              // if there are no full intersections — mark first from sorted as active
              target.classList.add(...this.activeClass);
            } else {
              target.classList.remove(...this.activeClass);
            }
          }
        });
      },
      {
        threshold: Array.from({ length: 20 }).map((_, index) => index / 20),
      }
    );
    this.itemTargets.forEach((target) => {
      const el = document.getElementById(target.dataset.value);
      observer.observe(el);
    });
  }
}
