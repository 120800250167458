import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class ModalComponentController extends Controller {
  static targets = ['modal'];

  connect() {
    setTimeout(() => {
      window.dispatchEvent(new Event('content-added', { content: this.element }));
    }, 1);

    this.closable = this.element.dataset.closable === 'true';

    if (this.element.dataset.delay) {
      setTimeout(() => this.open(), this.element.dataset.delay);
    }

    let formElement = this.modalTarget.querySelector('form');

    if (formElement && this.element.dataset.close_on_submit === 'true' && this.closable) {
      formElement.addEventListener('submit', (e) => {
        if (e.target.checkValidity()) {
          this.close({ skipEvent: true });
          setTimeout(function () {
            e.target.reset();
          }, 500);
        }
      });
    }
  }

  open() {
    this.modalTarget.classList.remove('hidden');
  }

  close({ skipEvent }) {
    if (!this.closable) {
      return false;
    }
    this.modalTarget.classList.add('hidden');
    if (!skipEvent && this.element.dataset.postToOnClose) {
      Rails.ajax({ url: this.element.dataset.postToOnClose, type: 'POST' });
    }
  }
}
