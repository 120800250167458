import { Controller } from 'stimulus';

export default class DropdownController extends Controller {
  connect() {
    this.isShown = false;
    const autoclose = this.element.hasAttribute('data-autoclose');

    const target = document.getElementById(this.element.dataset.target);

    this.element.addEventListener('click', (e) => {
      if (target.contains(e.target)) {
        return;
      }

      if (e.currentTarget === this.element) {
        e.preventDefault();

        if (this.isShown) {
          target.classList.remove('show');
          target.classList.remove('block');
          target.classList.add('hidden');
        } else {
          target.classList.add('show');
          target.classList.add('block');
          target.classList.remove('hidden');
        }

        this.isShown = !this.isShown;
      }
    });

    if (autoclose) {
      document.addEventListener('click', (e) => {
        if (this.isShown && !target.contains(e.target) && !this.element.contains(e.target)) {
          target.classList.remove('show');
          target.classList.remove('block');
          target.classList.add('hidden');
          this.isShown = false;
        }
      });
    }
  }
}
