import { Controller } from 'stimulus';
import intlTelInput from 'intl-tel-input';
import intlTelInputUtils from 'intl-tel-input/build/js/utils.js';

export default class extends Controller {
  static targets = ['input'];

  connect() {
    window.addEventListener('load', this.connectTelInput.bind(this));
    document.addEventListener('turbo:load', this.connectTelInput.bind(this));
    window.addEventListener('content-added', this.connectTelInput.bind(this));
  }

  connectTelInput() {
    if (this.inputTarget.dataset.intlTelInputId !== undefined) {
      return;
    }

    this.telInput = intlTelInput(this.inputTarget, {
      initialCountry: 'NO',
      preferredCountries: ['NO', 'SE', 'DK', 'DE'],
      utilsScript: intlTelInputUtils,
      customContainer: 'w-full',
      hiddenInput: 'mobile_phone_number',
      nationalMode: true,
      formatOnDisplay: true,
      separateDialCode: true,
    });

    this.inputTarget.addEventListener('keyup', () => this.validate());
    this.inputTarget.addEventListener('change', () => this.validate());
  }

  validate() {
    if (this.telInput.getNumber()) {
      this.telInput.setNumber(this.telInput.getNumber());

      if (this.telInput.isValidNumber()) {
        this.inputTarget.classList.remove('is-invalid');
        this.inputTarget.classList.add('is-valid');

        let hint = this.element.querySelector('.invalid-feedback');
        if (hint) {
          hint.classList.add('d-none');
        }
      } else {
        this.inputTarget.classList.add('is-invalid');
        this.inputTarget.classList.remove('is-valid');
      }
    }
  }
}
