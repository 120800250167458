import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.offset = this.element.offsetHeight - this.element.clientHeight;
    this.element.addEventListener('input', this.resize.bind(this));
    this.element.addEventListener('turbo:submit-end', this.reset.bind(this));
  }

  resize(event) {
    event.target.style.height = 'auto';
    event.target.style.height = event.target.scrollHeight + this.offset + 'px';
  }

  reset() {
    this.element.style.height = '';
    this.offset = this.element.offsetHeight - this.element.clientHeight;
  }

  disconnect() {
    this.element.removeEventListener('input', this.resize.bind(this));
    this.element.removeEventListener('turbo:submit-end', this.reset.bind(this));
  }
}
