import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['sidebar', 'content'];

  connect() {
    $('#topnav').classList.toggle('hidden');
    $('#topnav').classList.toggle('md:block');

    document.addEventListener('turbo:before-stream-render', (e) => {
      const origRender = e.detail.render;
      e.detail.render = (streamElement) => {
        if (
          streamElement.getAttribute('target') === 'conversation_messages' &&
          streamElement.getAttribute('action') === 'prepend'
        ) {
          this.removeTypingIndicator();
        }
        origRender(streamElement);
      };
    });
  }

  toggleSidebar() {
    $('#topnav').classList.toggle('hidden');
    $('#topnav').classList.toggle('md:block');
    this.sidebarTarget.classList.toggle('hidden');
    this.sidebarTarget.classList.toggle('md:block');
    this.contentTarget.classList.toggle('hidden');
    this.contentTarget.classList.toggle('md:block');
  }

  ask(e) {
    e.target.style = 'pointer-events: none; opacity: 0;';
    this.removeTypingIndicator();
  }

  removeTypingIndicator() {
    const indicator = this.element.querySelector('.typing-indicator-message');
    if (indicator) {
      indicator.remove();
    }
  }
}
