import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    if (this.element.dataset.autoclose) {
      setTimeout(() => {
        this.close();
      }, this.element.dataset.autoclose);
    }
  }

  close() {
    this.element.remove();
  }
}
