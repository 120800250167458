import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['checkbox', 'section'];

  toggle(event) {
    const elements = Array.from(event.target.form.elements).filter(
      (element) => !element.contains(event.target) && this.sectionTarget.contains(element)
    );
    if (event.target.checked) {
      elements.forEach((element) => {
        element.setAttribute('disabled', 'disabled');
        element.classList.add('opacity-25');
        element.classList.add('opacity-20');
        element.classList.add('pe-none');
      });
    } else {
      elements.forEach((element) => {
        element.removeAttribute('disabled');
        element.classList.remove('opacity-25');
        element.classList.remove('opacity-20');
        element.classList.remove('pe-none');
      });
    }
  }
}
