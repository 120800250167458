import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['item'];

  static values = {
    singular: Boolean, // in singular mode only one item will be open at a time
  };

  connect() {
    this.element.querySelectorAll('.heading').forEach((heading) => {
      heading.addEventListener('click', this.handleClick.bind(this));
    });
  }

  handleClick(event) {
    this.element.querySelectorAll('.heading').forEach((heading) => {
      if (this.singularValue) {
        if (heading !== event.currentTarget) {
          heading.classList.add('collapsed');
        } else {
          heading.classList.toggle('collapsed');
        }
      } else if (heading === event.currentTarget && heading.dataset.disabled !== 'true') {
        heading.classList.toggle('collapsed');
      }
    });
  }

  click(e) {
    for (let item of this.itemTargets) {
      if (this.singularValue) {
        if (item !== e.currentTarget) {
          item.removeAttribute('open');
        } else {
          item.setAttribute('open', '');
        }
      } else {
        if (item === e.currentTarget) {
          if (item.hasAttribute('open')) {
            item.removeAttribute('open');
          } else {
            item.setAttribute('open', '');
          }
        }
      }
    }
  }
}
