import {Controller} from 'stimulus'

export default class extends Controller {
    static targets = ['likeButton']

    static values = {
        isLiked: Boolean
    }

    toggleLike(_event) {
        this.likeButtonTarget.classList.remove('btn-primary')
        this.likeButtonTarget.classList.remove('btn-outline-primary')
        this.isLikedValue = !this.isLikedValue
        let counterEl = document.getElementById('confirmed-counter')
        let gotoConfirmationEl = document.getElementById('go-to-confirmation-button')

        if (this.isLikedValue) {
            this.likeButtonTarget.classList.add('btn-primary')
            this.likeButtonTarget.querySelector('.js-selected').classList.remove('d-none')
            this.likeButtonTarget.querySelector('.js-select').classList.add('d-none')

            counterEl.innerText = parseInt(counterEl.innerText) + 1
            gotoConfirmationEl.classList.remove('disabled')
        } else {
            this.likeButtonTarget.querySelector('.js-select').classList.remove('d-none')
            this.likeButtonTarget.querySelector('.js-selected').classList.add('d-none')
            this.likeButtonTarget.classList.add('btn-outline-primary')
            let newValue = parseInt(counterEl.innerText) - 1
            counterEl.innerText = newValue

            if (newValue === 0) {
                gotoConfirmationEl.classList.add('disabled')
            }
        }
    }
}
