import { Controller } from 'stimulus';

export default class extends Controller {
  show_alert(e) {
    const alert = document.createElement('div');
    alert.classList.add('alert', `alert-${e.detail.type}`, 'show');
    alert.innerHTML = e.detail.message;
    alert.dataset.controller = 'alert';

    alert.dataset.autoclose = e.detail.autoclose;

    const close = document.createElement('button');
    close.classList.add('btn-close');
    close.dataset.action = 'alert#close';

    alert.append(close);

    this.element.append(alert);
  }
}
