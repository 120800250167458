import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['stuckCounter', 'item'];

  connect() {
    this.updateCounters();
    this.initialized = true;
  }

  itemTargetConnected() {
    if (this.initialized) {
      this.updateCounters();
    }
  }

  updateCounters() {
    const groupedTargets = this.itemTargets.reduce((prev, next) => {
      return { ...prev, [next.dataset.stage]: [...(prev[next.dataset.stage] || []), next] };
    }, {});
    this.stuckCounterTargets.forEach((counter) => {
      const stuckCounter = groupedTargets[counter.dataset.stage].reduce((prev, next) => {
        return prev + (next.dataset.ignored === 'true' ? 0 : next.dataset.stuck === 'true' ? 1 : 0);
      }, 0);
      if (stuckCounter < 1) {
        counter.classList.add('hidden');
      } else {
        counter.classList.remove('hidden');
      }
      counter.querySelector('span').innerText = `${stuckCounter} stuck`;
    });
  }
}
