import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['item'];

  connect() {
    this.availableTargets = this.itemTargets;
  }

  setActive(event) {
    this.availableTargets.forEach((item) => item.classList.remove('active'));
    event.currentTarget.closest('[data-single-active-item-target="item"]').classList.add('active');
    window.history.pushState({}, '', event.currentTarget.dataset.location);
  }
}
