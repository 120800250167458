import { Controller } from 'stimulus';
import { Gauge } from 'gaugeJS';
export default class extends Controller {
  static values = {
    initial: Number,
  };

  connect() {
    const opts = {
      angle: -0.24,
      lineWidth: 0.21,
      radiusScale: 1,
      pointer: {
        length: 0.51,
        strokeWidth: 0.035,
        color: '#D1DEFF',
      },
      limitMax: true,
      limitMin: true,
      colorStart: '#6FADCF',
      colorStop: '#8FC0DA',
      strokeColor: '#E0E0E0',
      generateGradient: false,
      highDpiSupport: true,
      staticZones: [
        { strokeStyle: '#B7CCFF', min: 0, max: 1 },
        { strokeStyle: '#94B4FF', min: 1, max: 2 },
        { strokeStyle: '#6492FF', min: 2, max: 3 },
        { strokeStyle: '#457DFF', min: 3, max: 4 },
        { strokeStyle: '#175CFF', min: 4, max: 5 },
      ],
    };
    const gauge = new Gauge(this.element).setOptions(opts);
    gauge.minValue = 0;
    gauge.maxValue = 5;
    gauge.animationSpeed = 1;
    // gauge.setMinValue(0);
    gauge.set(this.initialValue);
  }
}
