import { Controller } from 'stimulus';
import loadGoogleMaps, { mapStyles } from '../utils/maps_loader';

export default class extends Controller {
  static values = {
    objects: Array,
  };

  connect() {
    loadGoogleMaps(this.initMap.bind(this));
  }

  initMap() {
    if (this.map) {
      // already initialized
      return;
    }

    let iconSize = 50;

    this.iconSecondary = {
      url: '/map-marker-secondary.svg',
      size: new google.maps.Size(iconSize, iconSize),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(iconSize / 2, iconSize / 2),
    };

    console.log('initMap');
    this.map = new google.maps.Map(this.element, {
      center: new google.maps.LatLng(this.objectsValue[0].latitude, this.objectsValue[0].longitude),
      zoom: 15,
      styles: mapStyles,
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      draggable: false,
      scrollwheel: false,
      disableDoubleClickZoom: true,
    });

    let bounds = new google.maps.LatLngBounds();

    this.objectsValue.forEach((object) => {
      let marker = new google.maps.Marker({
        map: this.map,
        icon: this.iconSecondary,
        clickable: false,
        anchorPoint: new google.maps.Point(-25, -25),
      });

      marker.setPosition(new google.maps.LatLng(object.latitude, object.longitude));
      marker.setVisible(true);
      bounds.extend(marker.getPosition());
    });

    google.maps.event.addListenerOnce(this.map, 'bounds_changed', () => {
      this.map.setZoom(this.map.getZoom() - 0.1);

      if (this.map.getZoom() > 15) {
        this.map.setZoom(15);
      }
    });
    this.map.fitBounds(bounds);
  }
}
