import { Controller } from 'stimulus';

function getCookie(cname) {
  var name = cname + '=';
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

export default class extends Controller {
  static targets = ['alert'];

  connect() {
    if (!getCookie('acceptedCookies') && window.appConfig.env !== 'staging') {
      setTimeout(() => {
        // if (document.querySelector('footer')){
        //   this.alertTarget.style.bottom = document.querySelector('footer').clientHeight + 'px';
        // }

        this.alertTarget.classList.add('show');
      }, 300);
    }
  }

  accept() {
    this.alertTarget.classList.remove('show');
    setCookie('acceptedCookies', true, 365);
  }
}
