import { Controller } from 'stimulus';

export default class extends Controller {
  switch(event) {
    // Workaround for switching locale in inbox
    if (
      document.body.dataset.railsAction === 'show' &&
      document.body.dataset.railsController === 'conversations' &&
      typeof URLSearchParams === 'function'
    ) {
      let searchParams = new URLSearchParams(window.location.search);
      searchParams.set('locale', event.currentTarget.dataset.localeValue);

      event.preventDefault();

      window.location = `${window.location.pathname}?${searchParams.toString()}`;
    }
  }
}
