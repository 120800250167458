import {Controller} from 'stimulus'
import {Chart} from 'frappe-charts'

export default class extends Controller {
    static values = {
        series: Object,
        type: String,
        height: Number,
    }

    connect() {
        let options = {}

        if (this.typeValue === 'area') {
            this.typeValue = 'line'

            options = {
                axisOptions: {
                    xIsSeries: true,
                    xAxisMode: 'tick',
                    yAxisMode: 'span'
                },
                lineOptions: {
                    hideDots: 1,
                    heatLine: 1,
                    regionFill: 1
                }
            }
        }

        new Chart(this.element, {
            data: this.seriesValue,
            type: this.typeValue, //'percentage', // or 'bar', 'line', 'scatter', 'pie', 'percentage'
            height: this.heightValue,
            truncateLegends: false,
            colors: ['#175cff', '#E67E22', '#FFC65A', '#10824B'],
            ...options
        })
    }
}
