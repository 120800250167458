import {Controller} from 'stimulus'

export default class extends Controller {
    static targets = ['toggleable']
    static values = {
        hide: Boolean,
    }

    connect() {
        this.hideValue = true
    }

    toggle() {
        this.hideValue = !this.hideValue
    }

    hideValueChanged() {
        this.toggleableTargets.forEach(target =>
            target.classList.toggle('hidden')
        )
    }
}