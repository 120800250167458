let loaded = false;
const queue = [];

export const mapStyles = [
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#d3d3d3',
      },
    ],
  },
  {
    featureType: 'transit',
    stylers: [
      {
        color: '#808080',
      },
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#b3b3b3',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry.fill',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#ffffff',
      },
      {
        weight: 1.8,
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#d7d7d7',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry.fill',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#ebebeb',
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry',
    stylers: [
      {
        color: '#a7a7a7',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry.fill',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#efefef',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#696969',
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'labels.text.fill',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#737373',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#d6d6d6',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {},
  {
    featureType: 'poi',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#dadada',
      },
    ],
  },
];

function loadReal(callback) {
  const existingScript = document.getElementById('googleMaps');

  if (!existingScript) {
    const script = document.createElement('script');
    script.src =
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyC4WWB8NU58gPP7yohgp-6V_PY9Tg6X1yk&libraries=places';
    script.id = 'googleMaps';
    document.body.appendChild(script);

    script.onload = () => {
      loaded = true;
      if (callback) callback();
      queue.forEach((c) => c());
      queue.length = 0;
    };
  }

  if (existingScript && callback) {
    if (loaded) {
      callback();
    } else {
      queue.push(callback);
    }
  }
}

function loadFake(callback) {
  const PLACES_MOCK = [
    {
      address_components: [
        {
          long_name: '40',
          short_name: '40',
          types: ['street_number'],
        },
        {
          long_name: 'Nydalen allé',
          short_name: 'Nydalen allé',
          types: ['route'],
        },
        {
          long_name: 'Nordre Aker',
          short_name: 'Nordre Aker',
          types: ['sublocality_level_1', 'sublocality', 'political'],
        },
        {
          long_name: 'Oslo',
          short_name: 'Oslo',
          types: ['postal_town'],
        },
        {
          long_name: 'Oslo kommune',
          short_name: 'Oslo kommune',
          types: ['administrative_area_level_2', 'political'],
        },
        {
          long_name: 'Oslo',
          short_name: 'Oslo',
          types: ['administrative_area_level_1', 'political'],
        },
        {
          long_name: 'Norway',
          short_name: 'NO',
          types: ['country', 'political'],
        },
        {
          long_name: '0484',
          short_name: '0484',
          types: ['postal_code'],
        },
      ],
      geometry: {
        location: {
          lat() {
            return 59.947941;
          },
          lng() {
            return 10.770242;
          },
        },
        viewport: {
          south: 59.9465920197085,
          west: 10.76889301970849,
          north: 59.9492899802915,
          east: 10.7715909802915,
        },
      },
      icon: 'https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png',
      name: 'Nydalen allé 40',
      description: 'Nydalen allé 40',
      place_id: 'nydalen',
      html_attributions: [],
    },
    {
      address_components: [
        {
          long_name: '12',
          short_name: '12',
          types: ['street_number'],
        },
        {
          long_name: 'Skytarberget',
          short_name: 'Skytarberget',
          types: ['route'],
        },
        {
          long_name: 'Molde',
          short_name: 'Molde',
          types: ['postal_town'],
        },
        {
          long_name: 'Molde',
          short_name: 'Molde',
          types: ['administrative_area_level_2', 'political'],
        },
        {
          long_name: 'Møre og Romsdal',
          short_name: 'Møre og Romsdal',
          types: ['administrative_area_level_1', 'political'],
        },
        {
          long_name: 'Norway',
          short_name: 'NO',
          types: ['country', 'political'],
        },
        {
          long_name: '6421',
          short_name: '6421',
          types: ['postal_code'],
        },
      ],
      geometry: {
        location: {
          lat() {
            return 62.7570393;
          },
          lng() {
            return 7.315711599999999;
          },
        },
        viewport: {
          south: 62.75582411970849,
          west: 7.314295219708498,
          north: 62.7585220802915,
          east: 7.316993180291503,
        },
      },
      icon: 'https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png',
      name: 'Skytarberget 12',
      description: 'Skytarberget 12, Molde',
      place_id: 'skytarberget',
      html_attributions: [],
    },
  ];

  setTimeout(function () {
    window.google = {
      maps: {
        Map: class {
          constructor() {}

          fitBounds() {}
        },
        Size: class {
          constructor() {}
        },
        Point: class {
          constructor() {}
        },
        Marker: class {
          constructor() {}

          setPosition() {}

          getPosition() {}

          setVisible() {}

          setIcon() {}

          addListener() {}
        },
        LatLng: class {
          constructor() {}

          lat() {}

          lng() {}
        },
        LatLngBounds: class {
          constructor() {}

          extend() {}
        },
        Circle: class {
          constructor() {}

          getBounds() {}

          setMap() {}
        },
        Geocoder: class {
          constructor() {}

          geocode(value, callback) {
            return callback(PLACES_MOCK, 'OK');
          }
        },
        places: {
          AutocompleteService: class {
            getPlacePredictions(_, callback) {
              return callback(PLACES_MOCK, 'OK');
            }
          },
          Autocomplete: class {
            constructor(target) {
              this.target = target;
              this.listeners = [];
              target.addEventListener('keyup', () => {
                this.optionsAdded = true;
                if (!this.resultEl) {
                  this.resultEl = document.createElement('span');
                  this.resultEl.classList = 'w-100 d-block pac-icon border';
                }

                this.resultEl.textContent = this.getPlace().name;

                if (!this.resultEl.parentNode) {
                  this.target.parentNode.appendChild(this.resultEl);
                  this.resultEl.addEventListener('click', () => {
                    target.value = this.resultEl.textContent;
                    this.resultEl.classList.add('d-none');
                  });
                }

                this.listeners.forEach((listener) => listener());
              });
            }

            bindTo() {}

            setFields() {}

            addListener(_event, callback) {
              this.listeners.push(callback);
            }

            getPlace() {
              let result = PLACES_MOCK[0];

              PLACES_MOCK.forEach((placeMock) => {
                if (placeMock.name.includes(this.target.value)) {
                  result = placeMock;
                }
              });

              return result;
            }
          },
        },
        event: {
          addListenerOnce() {},
        },
        events: {
          addListenerOnce() {},
        },
      },
    };

    if (window.initMap) {
      window.initMap();
    }

    if (callback) callback();
  }, 1);
}

export function polygonCoordinatesToLatLngArray(coordinates) {
  let latLngArrays = coordinates.map((polygon) => {
    return polygon.map((coordinate) => {
      return { lat: coordinate[1], lng: coordinate[0] };
    });
  });

  return latLngArrays;
}

export function multiPolygonCoordinatesToLatLngArray(coordinates) {
  const latLngArrays = coordinates
    .map((polygon) => {
      return polygon.map((coordinate) => {
        return coordinate.map((coordinate_1) => {
          return { lat: coordinate_1[1], lng: coordinate_1[0] };
        });
      });
    })
    .map((polygon) => {
      return polygon[0];
    });

  return latLngArrays;
}

export default function loadGoogleMaps(callback) {
  if (
    ['production', 'staging', 'development'].includes(window.appConfig.env) ||
    window.appConfig.use_google_maps
  ) {
    loadReal(callback);
  } else {
    loadFake(callback);
  }
}
