import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    let initialBottom;
    this.element.addEventListener('toggle', (e) => {
      if (e.detail && e.detail.action === 'open') {
        // only proceed when screen is rather small
        if (window.innerWidth > 800) {
          return false;
        }
        const rect = e.detail.resultsTarget.getBoundingClientRect();
        if (!initialBottom) {
          initialBottom = rect.bottom;
        }

        e.detail.inputTarget.scrollIntoView(true);
        e.detail.resultsTarget.style = `height: ${initialBottom - 20}px; max-height: none;`;
      }
    });
  }
}
