import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input'];

  static values = {
    autoSubmit: Boolean,
    externalId: String,
  };

  setValue(event) {
    let inputTarget = null;

    if (this.externalIdValue) {
      inputTarget = document.getElementById(this.externalIdValue);
    } else {
      inputTarget = this.inputTarget;
    }

    inputTarget.value = event.currentTarget.dataset.dynamicHiddenInputValue;

    if (this.autoSubmitValue) {
      inputTarget.form.requestSubmit();
    }
  }

  clear() {
    if (this.hasInputTarget) {
      this.inputTarget.value = null;
    }
  }
}
