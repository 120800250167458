import { Controller } from 'stimulus';

export default class SelectController extends Controller {
  static values = {
    autoClose: Boolean,
  };

  connect() {
    this.isShown = false;

    this.target = document.getElementById(this.element.dataset.target);

    this.forEachInput((input) => {
      input.addEventListener('change', this.inputChanged.bind(this));
    });
  }

  disconnect() {
    this.forEachInput((input) => {
      input.removeEventListener('change', this.changed);
    });
  }

  inputChanged() {
    let newLabel = [];
    let label = this.element.querySelector('[data-select-label]');

    if (label) {
      this.forEachInput((input) => {
        if (input.checked) {
          newLabel.push(input.dataset.labelText);
        }
      });

      if (newLabel.length === 0) {
        label.textContent = label.dataset.prompt;
      } else {
        label.textContent = newLabel.join(', ');
      }
    }

    if (this.autoCloseValue) {
      window.Stimulus.getControllerForElementAndIdentifier(this.element, 'dropdown-v2').hide();
    }
  }

  forEachInput(callback) {
    this.target.querySelectorAll('input').forEach(callback);
  }
}
