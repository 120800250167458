import Rails from '@rails/ujs';
import '@hotwired/turbo-rails';
import * as ActiveStorage from '@rails/activestorage';
import Hotjar from '@hotjar/browser';
import '@oddcamp/cocoon-vanilla-js';

import 'channels';
import 'controllers/index_v2';

window.Rails = Rails;

Rails.start();
ActiveStorage.start();

if (!window.appConfig.track_as_landing_page) {
  if (window.appConfig.env === 'production') {
    Hotjar.init(2696495, 6);
    if (window.appConfig.user_id) {
      Hotjar.identify(window.appConfig.user_id);
    }
  } else if (window.appConfig.env === 'staging') {
    Hotjar.init(2809435, 6);
    if (window.appConfig.user_testing_id) {
      Hotjar.identify(window.appConfig.user_testing_id);
    }
  }
}

document.addEventListener(
  'turbo:load',
  function (event) {
    if (
      !window.appConfig.track_as_landing_page &&
      (window.appConfig.env === 'staging' || window.appConfig.env === 'production')
    ) {
      Hotjar.stateChange(event.detail.url);

      if (window.appConfig.user_id) {
        Hotjar.identify(window.appConfig.user_id);
      } else if (window.appConfig.user_testing_id) {
        Hotjar.identify(window.appConfig.user_testing_id);
      }
    }
  },
  false
);

document.addEventListener('turbo:before-render', function (event) {
  window.HJFC = document.querySelector('._hj_feedback_container');
  window.HJFV = document.querySelector('._hjRemoteVarsFrame');
});

document.addEventListener('turbo:render', function (event) {
  if (window.HJFC) {
    document.body.appendChild(window.HJFC);
  }
  if (window.HJFV) {
    document.body.appendChild(window.HJFV);
  }
});

document.addEventListener('turbo:load', function () {
  document.querySelectorAll('.js-hint-wrapper').forEach(function (el) {
    if (!el.querySelector('.hint-content')) {
      el.querySelector('i').classList.add('hidden');
    }
  });

  document.body.addEventListener('ajax:success', function (event) {
    if (event.target.dataset.completeWith) {
      setTimeout(function () {
        event.target.innerHTML = event.target.dataset.completeWith;
        event.target.classList.add('disabled');
      }, 50);
    }
  });

  document.querySelectorAll('.form-group-invalid').forEach(function (invalidGroup) {
    let input = invalidGroup.querySelector('.is-invalid');

    if (input) {
      input.addEventListener('change', function () {
        let isInvalid = invalidGroup.querySelector('.is-invalid');
        if (isInvalid) {
          isInvalid.classList.remove('is-invalid');
        }

        let hint = invalidGroup.querySelector('.invalid-feedback');
        if (hint) {
          hint.classList.add('hidden');
        }
      });
    }
  });
});

function showDialog(message, element, onConfirm) {
  const modal = document.createElement('div');
  modal.innerHTML = document.getElementById('confirm-modal').innerHTML;
  modal.querySelector('.modal-component-content').innerText = message;
  modal.querySelector('button.submit').addEventListener('click', onConfirm.bind(modal));
  document.body.append(modal);
}

let skipConfirm = false;

Rails.confirm = function (message, element) {
  if (skipConfirm) {
    return true;
  }

  const onConfirm = function () {
    skipConfirm = true;
    element.click();
    skipConfirm = false;
    this.remove();
  };
  showDialog(message, element, onConfirm);
  return false;
};

window.$ = document.querySelector.bind(document);
window.$$ = document.querySelectorAll.bind(document);
