import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['checkbox', 'section'];

  connect() {
    this.toggle({ target: this.checkboxTarget });
  }

  toggle(event) {
    if (event.target.checked) {
      this.sectionTarget.classList.add('opacity-25');
      this.sectionTarget.classList.add('opacity-20');
      this.sectionTarget.classList.add('pe-none');
    } else {
      this.sectionTarget.classList.remove('opacity-25');
      this.sectionTarget.classList.remove('opacity-20');
      this.sectionTarget.classList.remove('pe-none');
    }
  }
}
