import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['sidebar', 'results', 'filters', 'map', 'showListButton', 'showMapButton'];

  static values = {
    baseUrl: String,
  };

  connect() {
    this.layoutClasses = ['show-map', 'show-list', 'show-filters'];
  }

  resetLayout() {
    this.layoutClasses.forEach((layoutClass) => {
      this.element.classList.remove(layoutClass);
    });
  }

  addLayoutClass(layoutClass) {
    window.requestAnimationFrame(() => {
      this.resetLayout();
      this.element.classList.add(layoutClass);
    });
  }

  showMap() {
    this.addLayoutClass('show-map');
  }

  showList() {
    this.addLayoutClass('show-list');
  }

  showFilters() {
    this.addLayoutClass('show-filters');
  }

  showResults() {
    this.addLayoutClass('show-list');
  }

  changeSize(e) {
    const split = e.target.value.split('-');
    const match = e.target.value.match(/(\d+)\+/);
    if (split.length > 1) {
      document.querySelectorAll('#size_from').forEach((el) => (el.value = split[0]));
      document.querySelectorAll('#size_to').forEach((el) => (el.value = split[1]));
    } else if (match) {
      document.querySelectorAll('#size_from').forEach((el) => (el.value = match[2]));
      document.querySelectorAll('#size_to').forEach((el) => (el.value = ''));
    }
    document
      .querySelectorAll('#size > option')
      .forEach((el) => (el.selected = el.value === e.target.value));
    // todo: this is not working as it should but leaving it here for intentionally
    // document.querySelectorAll('#size').forEach((el) => el.form.dispatchEvent(new Event('change')));
  }

  changeType(e) {
    // todo: this is not working as it should but leaving it here for intentionally
    // document.querySelectorAll('#type').forEach((el) => el.form.dispatchEvent(new Event('change')));
    document
      .querySelectorAll('#type > option')
      .forEach((el) => (el.selected = el.value === e.target.value));
  }

  search() {
    const form = document.getElementById('filters-search-form');
    if (form) {
      window.Rails.fire(form, 'submit');
      const formData = new FormData(form);
      const data = [...formData.entries()];
      const asString = data
        .map((x) => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`)
        .join('&');

      window.history.pushState({}, '', `${this.baseUrlValue}?${asString}`);
    }
  }

  clearFilterAndSubmit(e) {
    this.clearFilter(e.target.parentNode);
    this.search();
  }

  clearFilter(target) {
    const { key, value } = target.dataset;

    const form = document.getElementById('filters-search-form');
    const element = form.elements.namedItem(key) || form.elements.namedItem(`${key}[]`);

    if (element instanceof RadioNodeList) {
      Array.from(element.entries())
        .map((tuple) => tuple[1])
        .filter((item) => item.value === value || item.name === key)
        .forEach((el) => {
          el.checked = false;
        });
    } else if (element.tagName === 'SELECT') {
      element.querySelectorAll('option').forEach((el) => (el.selected = false));
    } else if (element.tagName === 'INPUT') {
      element.value = '';
      element.setAttribute('checked', false);
    }

    target.remove();
  }

  clearAllFilters(e) {
    Array.from(e.target.parentNode.children)
      .filter((el) => el !== e.target)
      .forEach((el) => this.clearFilter(el));
    this.search();
  }

  filtersTargetConnected() {
    this.filtersTarget
      .querySelector('form')
      .addEventListener('change', this.handleFormChange.bind(this));
  }

  filtersTargetDisconnected() {
    this.filtersTarget.querySelector('form').removeEventListener('change', this.handleFormChange);
  }

  handleFormChange() {
    this.search();
  }
}
