import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.items = JSON.parse(this.element.dataset.items);
    this.index = 0;
    this.thumbCount = parseInt(this.element.dataset.thumb_count, 10) || 4;

    if (this.items.length === 0) {
      return;
    }

    if ('inline' in this.element.dataset) {
      this.preload(this.index);
      this.mainContainer = this.element.querySelector('.main-image');
      const firstItem = document.createElement('img');
      this.mainContainer.append(firstItem);
      this.showImage(this.index);

      const prev = document.createElement('div');
      prev.classList.add('control', 'prev');
      prev.innerHTML = '<i class="icon chevron-up"></i>';
      prev.addEventListener('click', this.showPrev.bind(this));
      this.mainContainer.append(prev);

      const next = document.createElement('div');
      next.classList.add('control', 'next');
      next.innerHTML = '<i class="icon chevron-up"></i>';
      next.addEventListener('click', this.showNext.bind(this));
      this.mainContainer.append(next);
    }

    if ('thumbs' in this.element.dataset) {
      const thumbsContainer = this.element.querySelector('.thumbs');

      const firstItem = this.createThumb(this.index);
      thumbsContainer.replaceChild(firstItem, this.element.querySelector('.temp'));

      let i = 0;

      const fill = () => {
        i = i + 1;
        window.requestAnimationFrame(() => {
          if (
            thumbsContainer.getBoundingClientRect().width - 20 >
            firstItem.getBoundingClientRect().width * (i + (this.thumbCount - 2))
          ) {
            if (this.items[i]) {
              thumbsContainer.append(this.createThumb(i));
              fill();
            }
          } else {
            if (this.items.length - i === 1) {
              thumbsContainer.append(this.createThumb(i));
            } else if (i < this.items.length) {
              thumbsContainer.append(this.createRest(i));
            }
          }
        });
      };

      fill();
    }
  }

  createThumb(index) {
    const element = document.createElement('div');
    element.classList.add('item');
    element.style.backgroundImage = `url("${this.items[index][0]}")`;
    element.addEventListener('click', () => this.showModal(index));
    return element;
  }

  createRest(index) {
    const element = document.createElement('div');
    element.classList.add('item', 'rest');
    element.innerText = `+${this.items.length - index}`;
    element.addEventListener('click', () => this.showModal(index));
    return element;
  }

  showModal(index) {
    this.preload(index);
    this.modal = document.createElement('div');
    this.modal.classList.add('lightbox');
    this.modal.addEventListener('click', this.close.bind(this));

    const prev = document.createElement('div');
    prev.classList.add('control', 'prev');
    prev.innerHTML = '<i class="icon chevron-up"></i>';
    prev.addEventListener('click', this.showPrev.bind(this));
    this.modal.append(prev);

    const container = document.createElement('div');
    container.classList.add('content');
    this.modal.append(container);

    const img = document.createElement('img');
    img.addEventListener('click', (e) => e.stopPropagation());
    container.append(img);

    const position = document.createElement('div');
    position.classList.add('position');
    container.append(position);

    this.showImage(index);

    const next = document.createElement('div');
    next.classList.add('control', 'next');
    next.innerHTML = '<i class="icon chevron-up"></i>';
    next.addEventListener('click', this.showNext.bind(this));
    this.modal.append(next);

    const close = document.createElement('i');
    close.classList.add('icon', 'close');
    close.addEventListener('click', this.close.bind(this));
    this.modal.append(close);

    document.body.append(this.modal);
  }

  showPrev(e) {
    e.stopPropagation();
    this.showImage(this.prevNextIndex(this.index)[0]);
  }

  showNext(e) {
    e.stopPropagation();
    this.showImage(this.prevNextIndex(this.index)[1]);
  }

  showImage(index) {
    this.index = index;
    if (this.modal) {
      this.modal
        .querySelector('img')
        .replaceWith(window.imagesCache[this.items[index][1]].cloneNode());
      this.modal.querySelector('.position').innerText = `${index + 1}/${this.items.length}`;
    }
    if (this.mainContainer) {
      this.mainContainer
        .querySelector('img')
        .replaceWith(window.imagesCache[this.items[index][1]].cloneNode());
    }
    this.preloadClosest(index);
  }

  prevNextIndex(index) {
    if (this.items.length === 1) {
      return [0, 0];
    } else if (index === 0) {
      return [this.items.length - 1, index + 1];
    } else if (index === this.items.length - 1) {
      return [index - 1, 0];
    } else {
      return [index - 1, index + 1];
    }
  }

  preloadClosest(index) {
    const [prev, next] = this.prevNextIndex(index);
    this.preload(prev);
    this.preload(next);
  }

  preload(index) {
    window.imagesCache = window.imagesCache || {};
    const src = this.items[index][1];
    if (!window.imagesCache[src]) {
      const image = new Image();
      image.src = src;
      window.imagesCache[src] = image;
    }
  }

  close() {
    if (this.modal) {
      this.modal.remove();
      delete this.modal;
    }
  }
}
