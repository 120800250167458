import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input'];

  connect() {
    super.connect();

    this.inputTargets.forEach((input) => this.updatePreview(input));
  }

  change(event) {
    this.updatePreview(event.target);
  }

  updatePreview(input) {
    if (typeof input.dataset.outputId !== 'undefined') {
      document.getElementById(input.dataset.outputId).innerText = input.value;
    }

    if (typeof input.dataset.outputClass !== 'undefined') {
      document.querySelectorAll('.' + input.dataset.outputClass).forEach(function (el) {
        el.innerText = input.value;
      });
    }
  }
}
